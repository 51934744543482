import React, { useEffect, useState } from "react";
import Banner from "../components/banner";
import { useParams } from "react-router-dom";
// import ContentJSON from "../content.json";
import Content from "../components/content";
import useAPI from "../hooks/use-api";

function Product() {
  const { productSlug } = useParams();
  const api = useAPI();
  const [content, setContent] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const contentJSON = await api.getContent();
      setContent(contentJSON);
      setLoading(false);
    }
    fetchData();
  });

  if (isLoading) {
    return <h1 className="text-2xl p-5 text-center">Loading...</h1>;
  }

  return (
    <div>
      {content?.map((product) => {
        if (product.title === productSlug) {
          return (
            <div key={product?.title}>
              <div className="relative w-full">
                <div className="hidden md:block w-full">
                  <Banner
                    src={require(`@/assets/img/desktop_banner_${productSlug}.jpg`).default}
                    // src={`https://weblink.shopeepay.co.th/img/seainsure/desktop_banner_${productSlug}.jpg`}
                    style={{ width: '100%' }}
                    loading="lazy"
                  />
                </div>

                <div className="md:hidden w-full">
                  <Banner
                    className="object-fit"
                    src={require(`@/assets/img/mobile_banner_${productSlug}.jpg`).default}
                    // src={`https://weblink.shopeepay.co.th/img/seainsure/mobile_banner_${productSlug}.jpg`}
                    style={{ width: '100%' }}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="my-12 md:my-20">
                {product?.content.map((sub, index) => {
                  return <Content key={sub.title} data={sub} index={index} product={productSlug} />
                })}
              </div>
              <p className="text-right px-5 mb-2 mx-auto" style={{ maxWidth: '1260px' }}>
                สอบถาม/สั่งซื้อประกัน ติดต่อ 0 2040 8581 กด 2 หรือ อีเมล help@seainsurebroker.co.th
              </p>
            </div>
          )
        } else {
          return <div className="hidden" key={product?.title} />;
        }
      })}
    </div>
  );
}

export default Product;

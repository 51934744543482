import React from 'react'
import parse from 'html-react-parser'

function Content({ data, index, product }) {
  const imgSrc = require(`@/assets/img/${product}/${index + 1}.png`).default
  if ((index + 1) % 2 === 1) {
    return (
      <div>
        <div
          className="hidden md:flex flex-row mx-auto items-center justify-between mb-8 px-5"
          style={{ maxWidth: '920px' }}
        >
          <img alt="1" src={imgSrc} className="page-image" loading="lazy" />
          <div className="flex flex-col">
            <p className="title" style={{ maxWidth: '440px' }}>
              {parse(data?.title)}
            </p>
            <span className="text" style={{ maxWidth: '390px' }}>
              {parse(data?.desc)}
            </span>
          </div>
        </div>
        <div className="md:hidden flex flex-col items-center px-5">
          <img alt="1" src={imgSrc} className="w-4/5" loading="lazy" />
          <div className="my-4">
            <p className="title">{parse(data?.title)}</p>
            <span className="text">{parse(data?.desc)}</span>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className="hidden md:block content-alt mb-8">
          <div
            className="flex flex-row mx-auto items-center justify-between px-5"
            style={{ maxWidth: '920px' }}
          >
            <div className="flex flex-col">
              <p className="title" style={{ maxWidth: '440px' }}>
                {parse(data?.title)}
              </p>
              <span className="text" style={{ maxWidth: '390px' }}>
                {parse(data?.desc)}
              </span>
            </div>
            <img alt="1" src={imgSrc} className="page-image" loading="lazy" />
          </div>
        </div>
        <div className="md:hidden flex flex-col items-center ">
          <div className="content-alt-mobile">
            <img alt="1" src={imgSrc} className="w-4/5 px-5 mx-auto" loading="lazy" />
          </div>
          <div className="my-4 px-5">
            <p className="title">{parse(data?.title)}</p>
            <span className="text">{parse(data?.desc)}</span>
          </div>
        </div>
      </div>
    )
  }
}

export default Content

import axios from 'axios'
import { useContext } from 'react'
import { GlobalContext } from '../contexts/GlobalContext'
import claimJSON from '@/assets/data/claim.json'
import contentJSON from '@/assets/data/content.json'
import menuJSON from '@/assets/data/menu.json'
import partnerJSON from '@/assets/data/partner.json'

const sendEmailURL = process.env.REACT_APP_SEND_EMAIL_URL

const useAPI = () => {
  const [state, globalDispatch] = useContext(GlobalContext)

  const getClaim = async () => {
    let { claim } = state
    if (claim == null) {
      try {
        globalDispatch({ type: 'SET_CLAIM', payload: claimJSON })
        claim = claimJSON
      } catch {
        console.log(claim)
        // window.location = `/`;
      }
    }
    return claim
  }

  const getContent = async () => {
    let { content } = state
    if (content == null) {
      try {
        globalDispatch({ type: 'SET_CONTENT', payload: contentJSON })
        content = contentJSON
      } catch {
        console.log(content)
        // window.location = `/`;
      }
    }
    return content
  }

  const getMenu = async () => {
    let { menu } = state
    if (menu == null) {
      try {
        globalDispatch({ type: 'SET_MENU', payload: menuJSON })

        menu = menuJSON
      } catch {
        console.log(menu)
        // window.location = `/`;
      }
    }
    return menu
  }

  const getPartner = async () => {
    let { partner } = state
    if (partner == null) {
      try {
        globalDispatch({ type: 'SET_PARTNER', payload: partnerJSON })

        partner = partnerJSON
      } catch {
        console.log(partner)
        // window.location = `/`;
      }
    }
    return partner
  }

  const sendRequest = async payload => {
    let response = ''
    try {
      const res = await axios.post(sendEmailURL, {
        content: payload,
      })
      console.log(res)
      response = res.data.respData?.isReceive ? '' : 'error'
    } catch {
      return 'error'
    }
    return response
  }
  return {
    getClaim,
    getContent,
    getMenu,
    getPartner,
    sendRequest,
  }
}

export default useAPI

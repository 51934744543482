import React from "react";

function TC() {
  return (
    <div className="m-auto my-10" style={{ maxWidth: "1260px" }}>
      <h1 className="text-center md:my-20 px-5">
        ข้อกำหนดและเงื่อนไขการใช้บริการ
      </h1>
      <iframe
        title="Privacy Policy"
        src={process.env.PUBLIC_URL + '/assets/faq/tos.html'}
        // src="https://weblink.shopeepay.co.th/shopeepay/faq/seainsure/tos.html"
        width="100%"
        height="680vh"
        frameBorder="0"
        className="p-6"
      ></iframe>
    </div>
  );
}

export default TC;

import React, { createContext, useReducer } from "react";

export const GlobalContext = createContext();

const reducerFunction = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_CLAIM":
      return {
        ...state,
        claim: payload,
      };
    case "SET_CONTENT":
      return {
        ...state,
        content: payload,
      };
    case "SET_MENU":
      return {
        ...state,
        menu: payload,
      };
    case "SET_PARTNER":
      return {
        ...state,
        partner: payload,
      };
    default:
      return state;
  }
};

const initialState = {
  claim: null,
  content: null,
  menu: null,
  partner: null,
};
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducerFunction, initialState);

  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  );
};

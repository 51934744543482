import React from "react";
import Banner from "../components/banner";

function Payment() {
  return (
    <div>
      <div className="hidden md:block w-full">
        <Banner
          src={require('@/assets/img/desktop_banner_payment.jpg').default}
          // src={`https://weblink.shopeepay.co.th/img/seainsure/desktop_banner_payment.jpg`}
          style={{ width: '100%' }}
          loading="lazy"
        />
      </div>

      <div className="md:hidden w-full">
        <Banner
          className="object-fit"
          src={require('@/assets/img/mobile_banner_payment.jpg').default}
          // src={`https://weblink.shopeepay.co.th/img/seainsure/mobile_banner_payment.jpg`}
          style={{ width: '100%' }}
          loading="lazy"
        />
      </div>
      <div className="m-auto my-10" style={{ maxWidth: '1260px' }}>
        <h1 className="hidden md:block text-center px-5">วิธีการชำระค่าเบี้ยประกันภัย</h1>
        <h1 className="md:hidden text-center px-5">
          วิธีการชำระ
          <br />
          ค่าเบี้ยประกันภัย
        </h1>
        <div>
          <p className="md:hidden payment-text mt-10 mx-auto px-5">
            ลูกค้าสามารถชำระค่าเบี้ยประกันได้โดยการโอนเงินเข้า
            <br />
            <b style={{ textDecoration: 'underline' }}>
              บัญชีธนาคารกรุงเทพ
              <br />
              เลขที่บัญชี 911-0-16718-7
            </b>
          </p>
          <p
            className="hidden md:block payment-text mt-10 mx-auto "
            style={{ width: '585px', maxWidth: '100%' }}
          >
            ลูกค้าสามารถชำระค่าเบี้ยประกันได้โดยการโอนเงินเข้า
            <br />
            <b style={{ textDecoration: 'underline' }}>
              บัญชีธนาคารกรุงเทพ เลขที่บัญชี 911-0-16718-7
            </b>
          </p>
          <img
            alt="account"
            className="my-10 mx-auto px-5 md:px-0"
            style={{ width: '585px', maxWidth: '100%' }}
            loading="lazy"
            src={require('@/assets/img/payment/account.png').default}
          />
          <p
            className="payment-text mt-10 mx-auto px-5 md:px-0"
            style={{ width: '585px', maxWidth: '100%' }}
          >
            สำหรับการชำระเงินผ่านบัตรเครดิต กรุณาติดต่อเจ้าหน้าที่ <br />
            เบอร์ 02 040 8581 กด 2
          </p>
        </div>
      </div>
    </div>
  )
}

export default Payment;

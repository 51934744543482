import React from "react";
import PropTypes from "prop-types";

/**
 * Full width container
 */
const row = ({ className = "", children, ...props }) => {
  return (
    <div className={`w-full ${className}`} {...props}>
      {children}
    </div>
  );
};

row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default row;

import React, { useEffect, useState, useRef } from "react";
import "../App.css";
import { ReactComponent as DropdowmIcon } from "../assets/dropdown.svg";
import ListenForOutsideClick from "../hooks/listen-for-outside-clicks";
import useAPI from "../hooks/use-api";

function Navbar() {
  const api = useAPI();
  const bodyScrollLock = require("body-scroll-lock");
  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;

  const [showMenu, setShowMenu] = useState(true);
  const [menu, setMenu] = useState(true);
  const [isLoading, setLoading] = useState(true);
  function clickButtonMenu() {
    setShowMenu(!showMenu);
    if (showMenu) {
      disableBodyScroll(document.getElementById("mobile-menu"));
    } else {
      enableBodyScroll(document.getElementById("mobile-menu"));
    }
  }

  useEffect(() => {
    async function fetchData() {
      const menuJSON = await api.getMenu();
      setMenu(menuJSON);
      setLoading(false);
    }
    fetchData();
  });

  if (isLoading) {
    return <h1 className="text-2xl p-5">Loading...</h1>;
  }

  const sidebarMenu = {
    position: "absolute",
    right: "0",
    top: "48px",
    width: "250px",
    height: "calc(100% - 48px)",
    background: "#fafafa",
    zIndex: "100",
  };

  const sidebarMenuHidden = {
    display: "none",
  };

  const menuBackgroundHidden = {
    display: "none",
  };

  const menuBackground = {
    background: "rgba(0,0,0,.5)",
    zIndex: "98",
    position: "absolute",
    padding: "0",
    margin: "0",
    left: "0",
    right: "250px",
    height: "calc(100% - 48px)",
    top: "48px",
  };

  return (
    <div>
      <div id="mobile-menu" className="md:hidden">
        <nav className="navbar-mobile py-3 mx-4 flex flex-row justify-between">
          <a href="/">
            <img
              alt="logo"
              src={require('@/assets/img/logo.png').default}
              className="h-full"
              loading="lazy"
            />
          </a>

          <img
            alt="menu"
            className="p-1"
            onClick={() => clickButtonMenu()}
            src={require('@/assets/img/icon/menu.png').default}
            loading="lazy"
          />
        </nav>
        <div
          style={showMenu ? menuBackgroundHidden : menuBackground}
          onClick={() => clickButtonMenu()}
        />
        <div id="sidebarMenu" style={showMenu ? sidebarMenuHidden : sidebarMenu}>
          <div className="p-5">
            <div className="mb-4">
              <a href="/" className="font-bold" onClick={() => clickButtonMenu()}>
                หน้าแรก
              </a>
            </div>
            <div className="mb-4">
              <a href="/#history" className="text-black" onClick={() => clickButtonMenu()}>
                ประวัติบริษัท
              </a>
            </div>
            <div className="mb-6">
              <a href="/#partner" className="text-black" onClick={() => clickButtonMenu()}>
                พันธมิตรของเรา
              </a>
            </div>
            <p className="font-bold mb-4">บริการของเรา</p>
            <div className="mb-4">
              <a href="/property" className="text-black">
                การประกันภัยทรัพย์สิน
              </a>
            </div>
            <div className="mb-4">
              <a href="/marine" className="text-black">
                การประกันภัยทางทะเลและขนส่ง
              </a>
            </div>
            <div className="mb-4">
              <a href="/motor" className="text-black">
                การประกันภัยรถยนต์
              </a>
            </div>
            <div className="mb-4">
              <a href="/employee" className="text-black">
                การประกันภัยกลุ่ม
              </a>
            </div>
            <div className="mb-6">
              <a href="/miscellaneous" className="text-black">
                การประกันภัยเบ็ดเตล็ด
              </a>
            </div>
            <div className="mb-4">
              <p className=" font-bold">อื่นๆ</p>
            </div>
            <div className="mb-4">
              <a href="/claim" className="text-black">
                แจ้งเคลม
              </a>
            </div>
            <div className="mb-4">
              <a href="/payment" className="text-black">
                ช่องทางการชำระเงิน
              </a>
            </div>
            <div className="mb-4">
              <a href="/cancel" className="text-black">
                การยกเลิกคำสั่งซื้อและการคืนเงิน
              </a>
            </div>
            <div className="mb-4">
              <a href="/contact" className="text-black">
                ติดต่อเรา
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block px-5">
        <nav className="navbar py-4">
          <div className="flex flex-row justify-between m-auto" style={{ maxWidth: '1260px' }}>
            <a href="/">
              <img
                src={require('@/assets/img/logo.png').default}
                alt="logo"
                className="logo"
                loading="lazy"
              />
            </a>

            <ul className="navbar-nav my-auto">
              <NavItem icon={<DropdowmIcon />} text={menu[0]?.title}>
                <DropdownMenu list={menu[0]?.dropdown} />
              </NavItem>
              <NavItem icon={<DropdowmIcon />} text={menu[1]?.title}>
                <DropdownMenu list={menu[1]?.dropdown} />
              </NavItem>
              <NavItem text={menu[2]?.title} path={menu[2]?.path} />
              <NavItem icon={<DropdowmIcon />} text={menu[3]?.title}>
                <DropdownMenu list={menu[3]?.dropdown} />
              </NavItem>
              <NavItem text={menu[4]?.title} path={menu[4]?.path} />
            </ul>
          </div>
        </nav>
      </div>
    </div>
  )
}

function NavItem(props) {
  // const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  let toggle = () => setIsOpen(!isOpen);

  if (typeof props?.path !== "undefined") {
    toggle = () => (window.location = props.path);
  }

  // eslint-disable-next-line
  useEffect(ListenForOutsideClick(listening, setListening, menuRef, setIsOpen));

  return (
    <li className="nav-item px-4" ref={menuRef} onClick={() => toggle()}>
      <button className="icon-button">
        <span>{props.text}</span>
        {props.icon}
      </button>
      {isOpen && props.children}
    </li>
  );
}

function DropdownMenu(props) {
  function DropdownItem(props) {
    const odd = (props.num + 1) % 2 === 1;
    return (
      <div
        className={
          odd ? "menu-item justify-center odditem" : "menu-item justify-center"
        }
      >
        <p className="text-center">{props.children}</p>
      </div>
    );
  }
  return (
    <div className="dropdown pt-3 z-10">
      {props.list.map((service, key) => {
        return (
          <DropdownItem key={key} num={key}>
            <a href={service.path}>{service.title}</a>
          </DropdownItem>
        );
      })}
    </div>
  );
}
export default Navbar;

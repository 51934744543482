import React from "react";

function Policy() {
  return (
    <div className="m-auto my-10" style={{ maxWidth: '1260px' }}>
      <h1 className="text-center md:my-20 px-5">นโยบายความเป็นส่วนตัว</h1>
      <iframe
        title="Privacy Policy"
        src={process.env.PUBLIC_URL + '/assets/faq/policy.html'}
        // src="https://weblink.shopeepay.co.th/shopeepay/faq/seainsure/policy.html"
        width="100%"
        height="680vh"
        frameBorder="0"
        className="p-6"
      ></iframe>
    </div>
  )
}

export default Policy;

import React from 'react'
import Banner from '../components/banner'

function Cancel() {
  return (
    <div>
      <div className="hidden md:block w-full">
        <Banner
          src={require('@/assets/img/payment/desktop_banner_cancellations.jpg').default}
          style={{ width: '100%' }}
          loading="lazy"
        />
      </div>

      <div className="md:hidden w-full">
        <Banner
          className="object-fit"
          src={require('@/assets/img/payment/mobile_banner_cancellations.jpg').default}
          style={{ width: '100%' }}
          loading="lazy"
        />
      </div>
      <div className="m-auto my-10" style={{ maxWidth: '1260px' }}>
        <h1 className="hidden md:block text-center px-5">การยกเลิกคำสั่งซื้อ และคืนเงิน</h1>
        <h1 className="md:hidden text-center px-5">
          การยกเลิกคำสั่งซื้อ
          <br />
          และคืนเงิน
        </h1>
        <p className="cancel-text text-black px-5">
          <b>- หากลูกค้าประสงค์ยกเลิกการประกันภัยในกรณีที่กรมธรรม์ฯ ยังไม่เริ่มคุ้มครอง</b>
          &nbsp; ลูกค้าสามารถแจ้งความประสงค์ขอยกเลิกและจะได้รับเงินค่าเบี้ยประกันภัยคืน
          โดยค่าเบี้ยประกันภัยที่ลูกค้าจะได้รับคืนนั้น จะหักค่าธรรมเนียมธนาคาร ค่าธรรมเนียม payment
          gateway และค่าดำเนินการของบริษัท (ถ้ามี)
          <br />
          <br />
          <b>– หากลูกค้าประสงค์ยกเลิกการประกันภัยหลังจากกรมธรรม์ฯ ให้ความคุ้มครองแล้ว</b>
          &nbsp;ลูกค้าสามารถแจ้งความประสงค์ขอยกเลิกและจะได้รับเงินค่าเบี้ยประกันภัยคืนตามสัดส่วนอัตราการคืนเบี้ยประกันภัยที่สำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย
          (คปภ.) กำหนด
          <br />
          <br />
          หากลูกค้าประสงค์จะยกเลิกคำสั่งซื้อ หรือยกเลิกกรมธรรม์ประกันภัยตามที่ระบุข้างต้น โปรดติดต่อ{' '}
          <br /> บริษัท ซีอินชัวร์ โบรคเกอร์ จำกัด
          <br />
          อีเมล help@seainsurebroker.co.th
          <br />
          หรือ โทร 0 2040 8581 ณ เวลาทำการ: จันทร์ - ศุกร์ 08.30 - 17.30 น.
          <br />
          <br />
          <b>หมายเหตุ: เงื่อนไขอื่นๆ เป็นไปตามที่บริษัทฯ กำหนด</b>
        </p>
      </div>
    </div>
  )
}

export default Cancel

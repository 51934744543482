import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Product from "./pages/product";
import Claim from "./pages/claims";
import Payment from "./pages/payment";
import Cancel from "./pages/cancel";
import Contact from "./pages/contact";
import Policy from "./pages/policy";
import TC from "./pages/tc";
import useGaTracker from "./hooks/use-ga-tracker";

function App() {
  useGaTracker();
  return (
    <div
      id="wrapper"
      className="overflow-x-hidden h-full min-h-screen"
      style={{ maxWidth: "100vw" }}
    >
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/cancel" element={<Cancel />} />
        <Route exact path="/claim" element={<Claim />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/payment" element={<Payment />} />
        <Route exact path="/policy" element={<Policy />} />
        <Route exact path="/tc" element={<TC />} />
        <Route path="/:productSlug" element={<Product />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import React from "react";

function Footer() {
  return (
    <div>
      <div className="hidden md:block">
        <div className="border-t pt-12 mt-6 px-5">
          <div
            className="m-auto flex flex-row justify-between"
            style={{ maxWidth: "1260px" }}
          >
            <div className="w-1/2 flex flex-col">
              <img
                alt="logo"
                src={require("@/assets/img/logo.png").default}
                style={{ width: "244px" }}
                loading="lazy"
              />
              <p className="mt-12">โทร 02 040 8581</p>
              <p className="mt-5">อีเมล help@seainsurebroker.co.th</p>
              <p className="mt-6">
                ที่อยู่ 1788 สิงห์ คอมเพล็กซ์ ชั้น 26 ถนนเพชรบุรีตัดใหม่
                <br /> แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร 10310
              </p>
              <p className="mt-6">
                ท่านสามารถตรวจสอบข้อมูลรายชื่อนายหน้าที่สังกัดบริษัทได้
                ตามขั้นตอน ดังต่อไปนี้
              </p>
              <ol className="mt-3 list-decimal list-inside">
                <li>
                  เข้า
                  <a
                    className="text-blue-500 underline"
                    target="_blank"
                    href="https://smart.oic.or.th/EService/Menu1"
                    rel="noreferrer"
                  >
                    เว็บไซต์ของ คปภ
                  </a>
                  .
                </li>
                <li>
                  พิมพ์ ชื่อ - นามสกุล หรือ เลขที่ใบอนุญาตนายหน้าประกันภัย
                </li>
                <li>คลิกปุ่มค้นหา</li>
              </ol>
              <div className="flex flex-row items-center content-center my-3">
                <img
                  alt="OIC"
                  src={require("@/assets/img/OIC.png").default}
                  loading="lazy"
                />
                <p className="ml-3">ใบอนุญาตนายหน้าประกัน เลขที่ ว00002/2561</p>
              </div>
            </div>
            <div className=" w-1/2 flex flex-row justify-end">
              <div className="w-3/12 flex-col flex mr-2">
                <p className="mb-8 font-bold">หน้าแรก</p>
                <div className="mb-5 h-8">
                  <a href="/#history" className="text-black">
                    ประวัติบริษัท
                  </a>
                </div>
                <div className="mb-5 h-8">
                  <a href="/#partner" className="text-black">
                    พันธมิตรของเรา
                  </a>
                </div>
              </div>
              <div className="w-4/12 flex-col flex mr-2">
                <p className="mb-8 font-bold">บริการของเรา</p>
                <div className="mb-5 h-8">
                  <a href="/property" className="text-black">
                    การประกันภัยทรัพย์สิน
                  </a>
                </div>
                <div className=" lg:hidden mb-5 h-8">
                  <a href="/liability" className="text-black">
                    การประกันภัยความรับผิดชอบตามกฎหมาย
                  </a>
                </div>
                <div className=" hidden lg:block pr-10 mb-5 h-8">
                  <a href="/liability" className="text-black w-3/4">
                    การประกันภัยความรับผิดชอบตามกฎหมาย
                  </a>
                </div>
                <div className="mb-5 h-8">
                  <a href="/marine" className="text-black">
                    การประกันภัยทางทะเลและขนส่ง
                  </a>
                </div>
                <div className="mb-5 h-8">
                  <a href="/motor" className="text-black">
                    การประกันภัยรถยนต์
                  </a>
                </div>
                <div className="mb-5 h-8">
                  <a href="/employee" className="text-black">
                    การประกันภัยกลุ่ม
                  </a>
                </div>
                <div className="mb-5 h-8">
                  <a href="/miscellaneous" className="text-black">
                    การประกันภัยเบ็ดเตล็ด
                  </a>
                </div>
              </div>
              <div className="w-4/12 flex-col flex">
                <div className="mb-8">
                  <p className="font-bold">อื่นๆ</p>
                </div>
                <div className="mb-5 h-8">
                  <a href="/claim" className="text-black">
                    แจ้งเคลม
                  </a>
                </div>
                <div className="mb-5 h-8">
                  <a href="/payment" className="text-black">
                    ช่องทางการชำระเงิน
                  </a>
                </div>
                <div className="mb-5 h-8">
                  <a href="/cancel" className="text-black">
                    การยกเลิกคำสั่งซื้อและการคืนเงิน
                  </a>
                </div>
                <div className="mb-5 h-8">
                  <a href="/contact" className="text-black">
                    ติดต่อเรา
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 border-t">
            <p className="text-center p-6">
              © ลิขสิทธิ์ถูกต้อง บริษัท ซีอินชัวร์ โบรคเกอร์ จำกัด
              สงวนสิทธิ์ในการเป็นเจ้าของเครื่องหมายการค้า
              <a href="/tc"> เงื่อนไขการให้บริการ</a> |{" "}
              <a href="/policy">นโยบายความเป็นส่วนตัว</a>
            </p>
          </div>
        </div>
      </div>
      <div className="md:hidden border-t px-5">
        <img
          alt="logo"
          src={require("@/assets/img/logo.png").default}
          className="w-7/12 mt-8 mb-6"
          loading="lazy"
        />
        <p className="mb-2">โทร 02 040 8581</p>
        <p className="mb-2">อีเมล help@seainsurebroker.co.th</p>
        <p className="mb-2">
          ที่อยู่ 1788 สิงห์ คอมเพล็กซ์ ชั้น 26 ถนนเพชรบุรีตัดใหม่
          <br /> แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร 10310
        </p>

        <p className="mb-1 mt-3">
          ท่านสามารถตรวจสอบข้อมูลรายชื่อนายหน้าที่สังกัดบริษัทได้ ตามขั้นตอน
          ดังต่อไปนี้
        </p>
        <ol className="mb-2 list-decimal list-inside">
          <li>
            เข้า
            <a
              className="text-blue-500 underline"
              target="_blank"
              href="https://smart.oic.or.th/EService/Menu1"
              rel="noreferrer"
            >
              เว็บไซต์ของ คปภ
            </a>
            .
          </li>
          <li>พิมพ์ ชื่อ - นามสกุล หรือ เลขที่ใบอนุญาตนายหน้าประกันภัย</li>
          <li>คลิกปุ่มค้นหา</li>
        </ol>

        <div className="flex flex-row items-center content-center my-4">
          <img alt="OIC" src={require("@/assets/img/OIC.png").default} />
          <p className="ml-3">ใบอนุญาตนายหน้าประกัน เลขที่ ว00002/2561</p>
        </div>

        <div className="mb-4">
          <a href="/" className="font-bold">
            หน้าแรก
          </a>
        </div>
        <div className="mb-4">
          <a href="/#history" className="text-black">
            ประวัติบริษัท
          </a>
        </div>
        <div className="mb-6">
          <a href="/#partner" className="text-black">
            พันธมิตรของเรา
          </a>
        </div>
        <p className="font-bold mb-4">บริการของเรา</p>
        <div className="mb-4">
          <a href="/property" className="text-black">
            การประกันภัยทรัพย์สิน
          </a>
        </div>
        <div className="mb-4">
          <a href="/marine" className="text-black">
            การประกันภัยทางทะเลและขนส่ง
          </a>
        </div>
        <div className="mb-4">
          <a href="/motor" className="text-black">
            การประกันภัยรถยนต์
          </a>
        </div>
        <div className="mb-4">
          <a href="/employee" className="text-black">
            การประกันภัยกลุ่ม
          </a>
        </div>
        <div className="mb-6">
          <a href="/miscellaneous" className="text-black">
            การประกันภัยเบ็ดเตล็ด
          </a>
        </div>
        <div className="mb-4">
          <p className=" font-bold">อื่นๆ</p>
        </div>
        <div className="mb-4">
          <a href="/claim" className="text-black">
            แจ้งเคลม
          </a>
        </div>
        <div className="mb-4">
          <a href="/claim" className="text-black">
            ช่องทางการชำระเงิน
          </a>
        </div>
        <div className="mb-4">
          <a href="/cancel" className="text-black">
            การยกเลิกคำสั่งซื้อและการคืนเงิน
          </a>
        </div>
        <div className="mb-4">
          <a href="/contact" className="text-black">
            ติดต่อเรา
          </a>
        </div>
        <div className="border-t">
          <p className="text-center py-3">
            © ลิขสิทธิ์ถูกต้อง บริษัท ซีอินชัวร์ โบรคเกอร์ จำกัด
            <br />
            สงวนสิทธิ์ในการเป็นเจ้าของเครื่องหมายการค้า
            <br />
            <a href="/tc">เงื่อนไขการให้บริการ</a>&nbsp;|
            <a href="/policy">&nbsp;นโยบายความเป็นส่วนตัว</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;

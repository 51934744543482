import React, { useEffect, useState } from "react";
import Banner from "../components/banner";
import useAPI from "../hooks/use-api";

function Claim() {
  const api = useAPI();
  const [claim, setClaim] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const claimJSON = await api.getClaim();
      setClaim(claimJSON);
      setLoading(false);
    }
    fetchData();
  });

  if (isLoading) {
    return <h1 className="text-2xl p-5 text-center">Loading...</h1>;
  }

  return (
    <div>
      <div className="hidden md:block w-full">
        <Banner
          className="pointer-events-none"
          src={require('@/assets/img/claim/desktop_banner_claim.jpg').default}
        />
      </div>

      <div className="md:hidden w-full">
        <Banner
          className="object-fit pointer-events-none"
          src={require('@/assets/img/claim/mobile_banner_claim.jpg').default}
        />
      </div>
      <div className="m-auto my-10" style={{ maxWidth: '1260px' }}>
        <div>
          <h1 className="md:hidden text-center mb-4">
            วิธีการเรียกร้อง
            <br />
            ค่าสินไหมทดแทน
          </h1>
          <h1 className="hidden text-center mb-4">วิธีการเรียกร้องค่าสินไหมทดแทน</h1>
          <div className="claim-box-1-container mb-10 gap-6 mx-auto">
            <div className="claim-box-1 flex flex-col items-center justify-items-start p-4">
              <img
                alt="icon"
                className="w-1/4 py-6 pointer-events-none"
                loading="lazy"
                src={require('@/assets/img/icon/company.png').default}
              />
              <h1>สำหรับลูกค้าองค์กร</h1>
              <p className="text-center text">
                สามารถแจ้งการเรียกร้องค่าสินไหมทดแทนโดยติดต่อผ่านเรา
                <br />
                โทรศัพท์: 02 040 8581
                <br />
                อีเมล: help@seainsurebroker.co.th
              </p>
            </div>
            <div className="claim-box-1 flex flex-col items-center justify-items-start p-4">
              <img
                alt="icon"
                className="w-1/4 py-6 pointer-events-none"
                loading="lazy"
                src={require('@/assets/img/icon/individual.png').default}
              />
              <h1>สำหรับลูกค้าบุคคล</h1>
              <p className="hidden md:block text-center text">
                ลูกค้าผู้เอาประกันภัยสามารถดำเนินการเรียกร้องค่าสินไหมทดแทน
                <br />
                โดยตรงกับบริษัทประกันภัย หรือ <br />
                แจ้งการเรียกร้องค่าสินไหมทดแทนโดยติดต่อผ่านเรา <br /> โทรศัพท์: 02 040 8581 <br />{' '}
                อีเมล: help@seainsurebroker.co.th
              </p>
              <p className="md:hidden text-center text">
                ลูกค้าผู้เอาประกันภัยสามารถดำเนินการ
                <br />
                เรียกร้องค่าสินไหมทดแทน โดยตรงกับบริษัทประกันภัย หรือ
                แจ้งการเรียกร้องค่าสินไหมทดแทนโดยติดต่อผ่านเรา <br /> โทรศัพท์: 02 040 8581 <br />{' '}
                อีเมล: help@seainsurebroker.co.th
              </p>
              <button
                className={isOpen ? 'claim-button-open noSelect' : 'claim-button noSelect'}
                onClick={() => setIsOpen(!isOpen)}
              >
                คลิกเพื่อดูเบอร์ติดต่อบริษัทประกันภัย
              </button>
            </div>
          </div>
        </div>

        <div className={isOpen ? '' : 'hidden'}>
          <div className="my-10 md:my-20">
            <h1 className="text-center mt-10 mb-4 px-5">
              เบอร์ติดต่อบริษัทประกันภัย
              <br />
              สำหรับเรียกร้องค่าสินไหมทดแทน
            </h1>
            {/* {console.log(claim?.contact)} */}
            <div className="claim-box-2-container">
              {claim?.contact?.map((company, i) => {
                return (
                  <div key={i}>
                    <div
                      className="border rounded-2xl p-4"
                      style={{
                        minWidth: '373px',
                        minHeight: '339px',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <div className="flex items-center content-center h-2/5">
                        <img
                          alt={'partner'.concat(i)}
                          className="mx-auto object-contain pointer-events-none"
                          style={{
                            minHeight: '44px',
                            maxHeight: '108px',
                            height: '100%',
                          }}
                          src={require(`@/assets/img/partner/${company.title}.png`).default}
                        />
                      </div>
                      <div className="h-3/5 flex flex-col items-center content-center">
                        <div className="h-3/4 flex flex-col justify-center">
                          <div>
                            {console.log(company?.tel)}
                            {typeof company?.tel !== 'undefined' && (
                              <div className="relative rounded-full p-3">
                                <span
                                  className="text-2xl font-bold absolute"
                                  style={{
                                    color: '#333A41',
                                    top: '25%',
                                    left: '50%',
                                  }}
                                >
                                  {company?.tel}
                                </span>
                                <img
                                  alt="tel"
                                  className="w-3/5 mx-auto pointer-events-none"
                                  src={require('@/assets/img/claim/tel.png').default}
                                />
                              </div>
                            )}
                            {console.log(company?.phone)}
                            {typeof company?.phone !== 'undefined' &&
                              company?.phone.map((phoneNumber, index) => {
                                return (
                                  <div className="flex items-center justify-center">
                                    <img
                                      alt="tel"
                                      className="inline-block h-6 pointer-events-none"
                                      src={
                                        require('@/assets/img/claim/connection_phone__bill.svg')
                                          .default
                                      }
                                    />
                                    <div className="flex flex-col">
                                      <span
                                        className="ml-2 font-bold text-2xl"
                                        style={{ color: '#333A41' }}
                                      >
                                        {phoneNumber}
                                        {typeof company?.time !== 'undefined' && (
                                          <div className="-mt-1 text-base">
                                            <span>{company?.time[index]}</span>
                                          </div>
                                        )}
                                      </span>
                                      {}
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        </div>
                        <div className="h-1/4 flex flex-col justify-center">
                          <a
                            style={{ color: '#EA5F00' }}
                            className="text-base"
                            href={company?.http + company?.link}
                          >
                            {company?.link}
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <img
                      alt="partner"
                      loading="lazy"
                      src={`https://weblink.shopeepay.co.th/img/seainsure/claim/${
                        i + 1
                      }.png`}
                    /> */}
                  </div>
                )
              })}
            </div>
            <div className="md:hidden flex flex-col px-10 gap-6 w-full">
              {claim?.contact?.map((company, i) => {
                return (
                  <div className="w-full" key={i}>
                    <div
                      className="border rounded-2xl p-4 mx-auto"
                      style={{
                        width: '305.75px',
                        height: '277.88px',
                        maxHeight: '100%',
                        maxWidth: '100%',
                      }}
                    >
                      <div className="flex items-center content-center h-2/5">
                        <img
                          alt={'partner'.concat(i)}
                          className="mx-auto object-contain pointer-events-none"
                          style={{
                            minHeight: '44px',
                            maxHeight: '108px',
                            height: '100%',
                            width: 'auto',
                          }}
                          // src={`https://weblink.shopeepay.co.th/img/seainsure/partner/${company?.title}.png`}
                          src={require(`@/assets/img/partner/${company?.title}.png`).default}
                        />
                      </div>
                      <div className="h-3/5 flex flex-col items-center content-center my-2">
                        <div className="h-3/4 flex flex-col justify-center">
                          <div>
                            <div className="my-2">
                              {typeof company?.tel !== 'undefined' && (
                                <div className="relative rounded-full">
                                  <span
                                    className="text-2xl font-bold absolute"
                                    style={{
                                      color: '#333A41',
                                      top: '10%',
                                      left: '50%',
                                    }}
                                  >
                                    {company?.tel}
                                  </span>
                                  <img
                                    alt="tel"
                                    className="w-3/5 mx-auto pointer-events-none"
                                    src={require('@/assets/img/claim/tel.png').default}
                                  />
                                </div>
                              )}
                            </div>

                            {typeof company?.phone !== 'undefined' &&
                              company?.phone.map((phoneNumber, index) => {
                                return (
                                  <div className="flex items-center justify-center">
                                    <img
                                      alt="tel"
                                      className="inline-block h-6 pointer-events-none"
                                      src={
                                        require('@/assets/img/claim/connection_phone__bill.svg')
                                          .default
                                      }
                                    />
                                    <div className="flex flex-col">
                                      <span
                                        className="ml-2 font-bold text-2xl"
                                        style={{ color: '#333A41' }}
                                      >
                                        {phoneNumber}
                                        {typeof company?.time !== 'undefined' && (
                                          <div className="-mt-1 text-base">
                                            <span>{company?.time[index]}</span>
                                          </div>
                                        )}
                                      </span>
                                      {}
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        </div>
                        <div className="h-1/4 flex flex-col justify-center">
                          <a
                            style={{ color: '#EA5F00' }}
                            className="text-base"
                            href={company?.http + company?.link}
                          >
                            {company?.link}
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <img
                      alt="partner"
                      loading="lazy"
                      src={`https://weblink.shopeepay.co.th/img/seainsure/claim/${
                        i + 1
                      }.png`}
                    /> */}
                  </div>
                )
              })}
            </div>
          </div>

          <div>
            <h1 className="hidden md:block text-center mb-8">
              อู่ซ่อมและศูนย์บริการในเครือบริษัทประกันภัย
            </h1>
            <h1 className="md:hidden text-center mb-8">
              อู่ซ่อมและศูนย์บริการ
              <br />
              ในเครือบริษัทประกันภัย
            </h1>
            <div className="claim-box-3-container px-5">
              {claim?.garage?.map((company, index) => {
                return (
                  <div
                    key={index}
                    className="claim-box-3 flex flex-col items-center justify-center p-4"
                  >
                    <img
                      alt="logo"
                      loading="lazy"
                      className="m-auto object-contain p-3 pointer-events-none"
                      style={{ maxHeight: '100px' }}
                      // src={`https://weblink.shopeepay.co.th/img/seainsure/partner/${company}.png`}
                      src={require(`@/assets/img/partner/${company}.png`).default}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Claim;

import React from "react";
import PropTypes from "prop-types";
import Row from "./row";

/**
 * Use for image with full-width container.
 * If there is an error on "src", it will load placeholder image instead.
 */
const banner = ({ src = "", className = "", ...props }) => {
  return (
    <Row>
      <img
        alt="banner"
        src={src}
        className={`w-full h-auto ${className}`}
        {...props}
      />
    </Row>
  );
};

banner.propTypes = {
  /**
   * Image url
   */
  src: PropTypes.string,
  className: PropTypes.string,
};

export default banner;
